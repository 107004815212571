<template>
    <div class="join-div">
        <div style="padding-top: 3.95rem;">
            <img class="w-screen" :src="banner.cover">
            <div class="animate__animated animate__fadeIn"
                 style="position:absolute;top:35%;text-align:center;;width:100%;z-index:20;color: #f0f0f0">
                <div class="flex justify-center space-x-3.5" style="font-size:2.5rem;">
                    <h1 class="font-bold">{{ banner.title }}</h1>
                </div>
                <div class="text-center mt-5" style="font-size:large;font-weight:400;">{{ banner.intro }}</div>
            </div>
        </div>

        <div class="contact-div">
            <div class="recruit-div-title">
                联系方式
            </div>
            <div style=" height: 20rem; width: 100%;  display: flex;align-items: center;justify-content: center;" >
                <div class="contact-div-icon">
                    <img src="../assets/images/join/BFB-官网-加入我们-联系.jpg">
                </div>
                <div class="contact-div-moblie">
                    400-867-8690
                </div>
            </div>
        </div>

        <div class="recruit-div">
            <div class="recruit-div-title">
                人才招聘
            </div>
            <div class="recruit-content-div">
                <div @click="changeEemail(1)"
                     :class="{  'recruit-content-left-div email-bg-a':email_bg == 1,'recruit-content-left-div email-bg-b':email_bg != 1}">
                    <div class="recruit-content-box">
                        <span class="recruit-content-box-ad">杭州</span>
                        <img class="email-icon" v-show="email_bg == 1" src="../assets/images/join/BFB-官网-加入我们-邮箱.jpg">
                        <img class="email-icon" v-show="email_bg != 1" src="../assets/images/join/BFB-官网-加入我们-邮箱-2.jpg">
                        <span class="recruit-content-box-email">HZZP@bfbgroup.cn</span>
                    </div>
                </div>
                <div @click="changeEemail(2)"
                     :class="  {'recruit-content-right-div email-bg-a':email_bg == 2,'recruit-content-right-div email-bg-b': email_bg != 2}  ">
                    <div class="recruit-content-box">
                        <span class="recruit-content-box-ad">广州 </span>
                        <img class="email-icon" v-show="email_bg == 2" src="../assets/images/join/BFB-官网-加入我们-邮箱.jpg">
                        <img class="email-icon" v-show="email_bg != 2" src="../assets/images/join/BFB-官网-加入我们-邮箱-2.jpg">
                        <span class="recruit-content-box-email">hrgz@bfbgroup.cn</span>
                    </div>
                </div>
            </div>

        </div>
        <div class="amap-div">
            <div class="amap-div-map">
                <iframe
                    :src="location"
                    width="100%"
                    height="100%"
                    frameborder="0"
                >
                </iframe>
            </div>
            <div class="amap-div-add-list-div">
                <div @click="onMarkerClick(0)" class="amap-div-add-div">
                    <div class="amap-div-add-img-div">
                        <img style="width: 10rem;" src="../assets/images/join/亳州.png"/>
                    </div>
                    <div class="amap-div-add-txt-div">
                        <span >BFB集团（亳州）</span>
                        <p >毫州市高新区毫州芜湖现代产业园</p>
                    </div>
                </div>
                <div @click="onMarkerClick(1)" class="amap-div-add-div">
                    <div class="amap-div-add-img-div">
                        <img style="width: 10rem;" src="../assets/images/join/杭州.png"/>
                    </div>
                    <div class="amap-div-add-txt-div">
                        <span >BFB集团（杭州）</span>
                        <p >杭州市东宁路586号万象企业中心</p>
                    </div>

                </div>
                <div @click="onMarkerClick(2)" class="amap-div-add-div">
                    <div class="amap-div-add-img-div">
                        <img style="width: 10rem;" src="../assets/images/join/广州.png"/>
                    </div>
                    <div class="amap-div-add-txt-div">
                        <span>BFB集团（广州）</span>
                        <p >广州市白云区泰信智慧直播基地</p>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
import {ref} from "vue";

const banner = ref({})
const getBanner = async () => {
    await fetch("/api/banner/detail/5").then(response => response.json()
    ).then(data => {
        banner.value = data
    }).catch(error => {
        console.error('请求失败', error);
    });
}
getBanner()
</script>

<script>
export default {
    name: 'JoinUs',
    computed: {},
    data() {
        return {
            email_bg: 1,
            location: 'https://m.amap.com/navi/?dest=115.733323,33.778162&destName=毫州市高新区毫州芜湖现代产业园&hideRouteIcon=1&key=3cac8de669597c4aa51986e5111f96d4',
            locationList: [
                'https://m.amap.com/navi/?dest=115.733323,33.778162&destName=毫州市高新区毫州芜湖现代产业园&hideRouteIcon=1&key=3cac8de669597c4aa51986e5111f96d4',
                'https://m.amap.com/navi/?dest=120.215925,30.294871&destName=杭州市东宁路586号万象企业中心&hideRouteIcon=1&key=3cac8de669597c4aa51986e5111f96d4',
                'https://m.amap.com/navi/?dest=113.271289,23.222091&destName=广州市白云区泰信智慧直播基地&hideRouteIcon=1&key=3cac8de669597c4aa51986e5111f96d4',
            ]
        }
    },
    methods: {
        changeEemail(e) {
            this.email_bg = e
        },
        // 处理标记点点击事件
        onMarkerClick(e) {
            this.location = this.locationList[e];
            console.log(e)

        }
    }
}
</script>


<style>


.join-div {
    background-color: #f0f0f0;
}

.contact-div {
    background-color: #FFFFFF;
    margin: 3rem;
    width: 96%;
    height: 27rem;
    border-radius: 1rem;
    background-image: url('../assets/images/join/BFB-官网-加入我们-.jpg');
    background-size: contain; /* 控制背景图的大小，contain 保持原始比例，完全包含在容器中 */
    background-repeat: no-repeat; /* 防止背景图重复 */
    background-position: right; /* 将背景图靠右对齐 */
}

.contact-div-title {
    margin: 5rem 5rem 0rem 11rem;
    float: left;
    font-weight: bold;
    font-size: 2rem;
}

.contact-div-icon {
    margin: 0rem 1rem 0rem 0rem;
    float: left;
}

.contact-div-icon img {
    height: 5rem;
}

.contact-div-moblie {
    margin: 1rem 0rem 0rem 0rem;
    float: left;
    font-size: 4rem;
    font-weight: bold;
}

.contact-div-bg {
    position: absolute;
    z-index:999;
    right: 3.4rem;

}

.contact-div-bg img {
    width: 33rem;
}

.recruit-div {
    background-color: #FFFFFF;
    margin: 3rem;
    width: 96%;
    height: 27rem;
    border-radius: 1rem;
}

.recruit-div-title {
    padding: 5rem 5rem 0rem 10rem;
    font-weight: bold;
    font-size: 2.5rem;
}

.recruit-content-div {
    height: 15rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.recruit-content-left-div {
    margin-right: 5rem;
    float: left;
    height: 8rem;
    width: 30rem;
}

.email-icon {
    width: 2.4rem;
    margin: 0 1rem 0 1rem
}

.recruit-content-right-div {
    margin-left: 5rem;
    float: left;
    height: 8rem;
    width: 30rem;
}

.recruit-content-box {
    margin: 2rem 3rem 2rem 3rem;
    height: 4rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.recruit-content-box-ad {
    font-size: 1.8rem;
    font-weight: bold;
}

.recruit-content-box-email {
    font-size: 1.8rem;
}

.email-bg-a {
    background-color: #FFC80F
}

.email-bg-b {
    background-color: #FFF4D4
}

.amap-div {
    background-color: #f0f0f0;
    margin: 3rem;
    width: 96%;
    height: 68rem;
    border-radius: 1rem;
}

.amap-div-map {
    width: 70%;
    height: 64rem;
    margin: 1rem 0 1rem 0;
    float: left;
    z-index: 1;
    position: absolute;
}

.amap-div-add-list-div {
    width: 96%;
    padding-left: 55rem;
    z-index: 2;
    position: absolute;
}

.amap-div-add-div {
    width: 65%;
    float: right;
    border-radius: 35rem 0 0 35rem;
    margin: 1rem 0 1rem 0;
    height: 20rem;
    background-color: #ffffff
}

.amap-div-add-img-div {
    padding: 5rem 0 0 5rem;
    float: left;
}
.amap-div-add-txt-div {
    padding: 7rem 0 0 20rem;
}
.amap-div-add-txt-div span {
    font-size: 1.5rem;font-weight: bold;line-height: 3rem
}
.amap-div-add-txt-div p {
    font-size: 1.2rem;font-weight: bold
}

</style>
