<template>
  <div class="home-page-container mb-24">
    <section class="bg-center bg-no-repeat bg-cover" style="height: 680px"
             :style="{ backgroundImage: 'url(' + banner.cover + ')'}">
      <div class="banner">
        <div class="animate__animated animate__fadeIn"  style="position: absolute;font-size: 400px;text-align: center; top: 20%; width: 100%; z-index: 20">
          <div class="flex justify-center space-x-3.5" style="font-size: 4rem">
            <h1 class="font-bold">{{ banner.title }}</h1>
            <h1 class="font-light">{{ banner.subtitle }}</h1>
          </div>
          <div class="text-center mt-5" style="font-size: large;font-weight:400">{{ banner.intro }}</div>
        </div>
      </div>
    </section>
    <!--    <div class="h-full bg-no-repeat bg-cover max-h-96" style="height: 900px" :style="{ backgroundImage: 'url(' + bannerImg + ')'}">-->
    <!--      <div class="banner">-->
    <!--        <div class="banner-content animate__animated animate__fadeIn">-->
    <!--          <div class="flex justify-center max-auto space-x-1 text-center">-->
    <!--            <h1 class="text-5xl font-bold">绿色科技</h1>-->
    <!--            <h1 class="font-light text-5xl">健康最美</h1>-->
    <!--          </div>-->
    <!--          <div class="text-center mt-7">BEST FOR BEAUTY</div>-->
    <!--        </div>-->
    <!--        <video src="https://static.alibabagroup.com/static/fb23a195-4822-4688-9809-db1609ae29c9.mp4" autoplay="autoplay"-->
    <!--               poster="https://static.alibabagroup.com/static/5cfaedc7-88f4-415e-89d0-dd28af114b0f.jpg" class="video"-->
    <!--               loop="" playsinline="" x5-playsinline="true" webkit-playsinline="true"-->
    <!--               x5-video-player-fullscreen="true"-->
    <!--               style="object-fit: cover; object-position: 50% 60%; width: 100%; height: 100%; position: absolute; left: 0px; top: 0px; z-index: 6;"></video>-->
    <!--        <video poster="https://static.alibabagroup.com/static/5cfaedc7-88f4-415e-89d0-dd28af114b0f.jpg" class="video"-->
    <!--               loop="" playsinline="" x5-playsinline="true" webkit-playsinline="true" x5-video-player-fullscreen="true"-->
    <!--               style="object-fit: cover; object-position: 50% 60%; width: 100%; height: 100%; position: absolute; left: 0px; top: 0px; z-index: 7; display: none;"></video>-->
    <!--      </div>-->
    <!--    </div>-->

    <section class="mt-[-95px] bg-white mx-auto rounded-2xl" style="width: 96%">
      <div class="p-5">
        <h3 class="home-news-title animate__animated animate__fadeInDown">旗下品牌</h3>
        <div class="mt-10 w-full px-16">
          <BrandCarousel :items="brands"/>
          <div class="flex mt-12 justify-center">
            <hr class="w-11/12 border-solid border-gray-900"/>
          </div>
        </div>

        <div class="flex justify-center items-center mt-10 mb-10">
          <a class="flex justify-center text-center mx-auto  w-36  items-center border border-gray-500 text-1xl"
             href="/brands">查看更多</a>
        </div>
      </div>
    </section>

    <section class="bg-white h-screen w-11/12 mx-auto mt-7 rounded-2xl" style="height:100%; width: 96%">
      <div class="news-bg slide-top-bg"></div>
      <div>
        <div class="home-news-title animate__animated animate__fadeInDown">新闻动态</div>
        <div class="component-news-container">
          <div dir="ltr" role="row" class="next-row" device="desktop"
               style="margin-left: -10px; margin-right: -10px; margin-bottom: 20px;">
            <div dir="ltr" role="gridcell" class="next-col next-col-16" device="desktop"
                 style="padding-left: 10px; padding-right: 10px;"><a
                class="component-new-item common-border-radius transition-hover first"
                :href="/news-detail/ + topNews.id" target="_blank">
              <div class="component-index-news-cover"
                   :style="{'backgroundImage': 'url(' + topNews.cover + ')'}"
                   style="background-repeat: no-repeat; background-size: cover; background-position: right top;"></div>
              <div class="component-news-info">
                <div class="component-title">
                  {{ topNews.title }}
                </div>
                <div class="date">
                  <div class="first-date">{{ topNews.date }}</div>
                </div>
              </div>
            </a></div>
            <div dir="ltr" role="gridcell" class="next-col next-col-8" device="desktop"
                 style="padding-left: 10px; padding-right: 10px;"><a
                class="component-new-item common-border-radius transition-hover " :href="/news-detail/ + secNews.id"
                target="_blank">
              <div class="component-index-news-cover"
                   :style="{'backgroundImage': 'url(' + secNews.cover + ')'}"
                   style="background-repeat: no-repeat; background-size: cover; background-position: right top;"></div>
              <div class="component-news-info">
                <div class="component-title">
                  {{ secNews.title }}
                </div>
                <div class="date">
                  <div class="common-font-size-12 date-arrow">{{ secNews.date }}<i
                      class="next-icon next-icon-arrow-right next-medium"></i></div>
                </div>
              </div>
            </a></div>
          </div>
          <div dir="ltr" role="row" class="next-row" device="desktop" style="margin-left: -10px; margin-right: -10px;">
            <div v-for="newsItem in newsList.slice(2,5)" :key="newsItem.title" dir="ltr" role="gridcell"
                 class="shadow next-col next-col-8" device="desktop"
                 style="padding-left: 10px; padding-right: 10px;"><a
                class="component-new-item common-border-radius transition-hover " :href="/news-detail/ + newsItem.id"
                target="_blank">
              <div class="component-index-news-cover"
                   style="background-repeat: no-repeat; background-size: cover; background-position: right top;"
                   :style="{'backgroundImage': 'url(' + newsItem.cover + ')'}"></div>
              <div class="component-news-info">
                <div class="component-title" style="height: 7.75rem">{{ newsItem.title }}</div>
                <div class="date">
                  <div class="common-font-size-12 date-arrow">{{ newsItem.date }}<i
                      class="next-icon next-icon-arrow-right next-medium"></i></div>
                </div>
              </div>
            </a></div>
          </div>
        </div>
        <div class="flex justify-center items-center mb-20 py-12">
          <a class="flex justify-center text-center mx-auto w-36 items-center border border-gray-500 text-1xl"
             href="/news">查看更多</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import BrandCarousel from "@/components/home/BrandCarousel.vue";
const banner = ref({})
// const bannerImg = ref(require("@/assets/首页/首页-新.jpg"))
const brands = ref([
  {
    image: require("@/assets/首页/首页-多燕瘦.png"),
    url: '/',
    title: '1312',
  },
  {
    image: require("@/assets/首页/首页-轻食兔.png"),
    url: '/',
    title: '2',
  },
  {
    image: require("@/assets/首页/首页-kooyo.png"),
    url: '/',
    title: '22',
  },
])

const newsList = ref([])

const topNews = ref({
  title: '',
  intro: '',
  cover: '',
  date: '',
  id: '',
})

const secNews = ref({
  title: '',
  intro: '',
  cover: '',
  date: '',
  id: '',
})

const getNewsList = async () => {
  let response = await fetch('/api/news/top')
  newsList.value = await response.json()
  console.log(newsList.value)
  topNews.value = newsList.value[0]
  secNews.value = newsList.value[1]
}
const getBanner = async () => {
    await fetch("/api/banner/detail/1").then(response => response.json()
    ).then(data => {
        banner.value = data
    }).catch(error => {
        console.error('请求失败', error);
    });
}
onMounted(() => {
  // 自动播放video
  // const video = document.getElementById('video');
  // if (video) {
  //   video.play();
  // }
  getNewsList()
    getBanner()
})
</script>

<style scoped>


</style>
