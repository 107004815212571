<template>
  <div class="w-full">
    <div class="nav-header-wrap bg-white" >
      <div class="nav-header">
        <div class="nav-header-left">
          <a href="/">
            <div class="nav-header-left-img" style="height: 5.175rem;width: 7.125rem"></div>
          </a>
        </div>
        <nav class="flex justify-between content-center text-center items-center w-1/2 p-0 ml-32">
          <router-link
              v-for="router in routers"
              :key="router.name"
              :to="router.path"
              :active-class="'text-yellow'"
              class="text-black hover:text-yellow hover:scale-125 transition duration-300">
            {{ router.name }}
          </router-link>
        </nav>
        <div class="flex items-center ml-20 text-black">联系专线：400-867-8690</div>
        <div class="menu-icon">
          <div>
            <div class="line-first">
            </div>
            <div class="line-second"></div>
          </div>
        </div>
      </div>
      <div class="box">

      </div>
    </div>
  </div>

</template>

<script setup>
import {ref} from "vue";

const routers = ref([
  {name: "首页", path: "/"},
  {name: "关于我们", path: "/about"},
  {name: "旗下品牌", path: "/brands"},
  {name: "集团资讯", path: "/news"},
  {name: "加入我们", path: "/join"},
]);

// const companyLogo = ref("https://static.dingtalk.com/media/lALPDeC2_6qJqo_NAgfNAgc_519_519.png_620x10000q90.jpg")
</script>

<style scoped>
/* 样式 */
.nav-link {
  /* 添加你的普通样式 */
  text-decoration: none;
  padding: 1rem 1rem;
  flex-grow: 1;
}

.text-yellow {
  color: rgb(253, 202, 72);
}
</style>
