<template>
    <div style="background-color: #f0f0f0">
        <div style="padding-top: 3.95rem;">
            <img class="w-screen" :src="banner.cover">
            <div class="animate__animated animate__fadeIn"
                 style="position:absolute;top:35%;text-align:center;;width:100%;z-index:20;color: #f0f0f0">
                <div class="flex justify-center space-x-3.5" style="font-size:2.5rem;">
                    <h1 class="font-bold">{{ banner.title }}</h1>
                </div>
                <div class="text-center mt-5" style="font-size:large;font-weight:400;">{{ banner.intro }}</div>
            </div>
        </div>
        <div class="bands-jz-div">
            <p class="bands-jz-title">品牌矩阵</p>
            <div class="bands-jz-content content-center">
                BFB集团现运营品牌3个<br/>
                规划品牌10余个，<br/>
                旨在打造多品类、多元化品牌矩阵

            </div>
            <div style="height: 15rem;">
                <div class="bands-jz content-center">
                    <img @click="bandsChange('dys')" src="../assets/images/brands/logo-1.png">
                    <img @click="bandsChange('kooyo')" src="../assets/images/brands/logo-2.png">
                    <img src="../assets/images/brands/logo-3.png">
                </div>
            </div>
        </div>
        <div class="bands-hs-div w-screen" v-show="bands_show === 'dys'">
            <p class="bands-jz-title">核心品牌</p>
            <div style="height: 65rem">
                <div style="height:30rem; " class="content-center">
                    <div style="width:25rem;margin-right:10%;float: left">
                        <img style="width: 20rem" src="../assets/images/brands/avatar.png"/>
                        <p style="font-weight: bold;font-size: 1.6rem;padding:1rem 0rem 0rem 8rem">郑多燕</p>
                        <p style="font-size: 1.3rem;padding:1rem 0rem 0rem 5rem;color: #ABABAA">多燕瘦品牌创始人</p>
                    </div>
                    <div style="width:30rem;margin-left:10%;float: left">
                        <p style="font-size: 1.6rem;font-weight: bold">多燕瘦 Dyesoo</p>
                        <p style="font-size: 1.6rem;color: #929291;line-height: 3rem">大餐怕长肉，就吃多燕瘦</p>
                        <p style="font-size: 1.2rem;color: #929291;line-height: 2rem;padding-top: 2rem;">2015年，郑多燕按照“
                            <soap style="font-weight: bold;color: #1f1f1f">3分动、7分吃</soap>
                            ”的理念。<br/>
                            创立多燕瘦DyeS0o品牌，在科学运动的基础上，<br/>
                            引入膳食补充方案，帮助更多人实现形体管理目<br/>
                            标。
                        </p>
                        <div style="float: left;width: 30rem;margin:5rem 0rem 0rem 10rem;padding-left: 16rem">
                            <button style="font-size: 1.2rem;border: #1f1f1f solid 1px;padding: 0rem 2rem 0rem 2rem ">
                                查看更多
                            </button>
                        </div>
                    </div>
                </div>
                <div style="height:35rem;margin: 6rem 10rem 0rem 10rem" class="content-center">
                    <img style="width: 94%;" src="../assets/images/brands/旗下品牌-多燕瘦产品海报.jpg">
                    <br/>
                </div>
                <div style="height:1rem;margin: 10rem 15rem 0rem 15rem">
                    <hr/>
                </div>

            </div>
        </div>
        <div class="bands-hs-div w-screen" v-show="bands_show === 'kooyo'">
            <p class="bands-jz-title">核心品牌</p>
            <div style="height: 65rem">
                <div style="height:30rem;" class="content-center">
                    <div style="width:25rem;margin-right: 10%;;float: left">
                        <img style="width: 20rem" src="../assets/images/brands/kooyo-avatar.png"/>
                        <p style="font-weight: bold;font-size: 1.6rem;padding:1rem 0rem 0rem 0rem">DR.THOMAS ROBERT CECH
                        </p>
                        <p style="font-size: 1.3rem;padding:1rem 0rem 0rem 0rem;color: #ABABAA">
                            <span style="font-weight: bold;color: #1f1f1f">诺贝尔化学奖获得者</span>
                            品牌首席科学家
                        </p>
                    </div>
                    <div style="width:30rem;margin-left: 10%;;float: left;">
                        <p style="font-size: 1.6rem;font-weight: bold">KOOYO</p>
                        <p style="font-size: 1.6rem;color: #929291;line-height: 3rem">新一代严肃保健品</p>
                        <p style="font-size: 1.2rem;color: #929291;line-height: 2rem;padding-top: 2rem;">
                            2019年，KOOYO成立于美国加州。<br/>
                            秉持“ <span
                            style="font-weight: bold;color: #1f1f1f">科技温暖家庭</span>”的理念，KOOYO的首条<br/>
                            产品线“THEONE”坚持研发有温度的科学产<br/>
                            品，链接不同场景下的细分需求，守护新一代<br/>
                            家庭的健康。
                        </p>

                        <div style="float: left;width: 30rem;margin:5rem 0rem 0rem 10rem;padding-left: 16rem">
                            <button style="font-size: 1.2rem;border: #1f1f1f solid 1px;padding: 0rem 2rem 0rem 2rem ">
                                查看更多
                            </button>
                        </div>
                    </div>
                </div>
                <div style="height:35rem;margin: 6rem 10rem 0rem 10rem" class="content-center">
                    <img style="width: 94%;" src="../assets/images/brands/旗下品牌-KOOYO产品海报.jpg">
                    <br/>
                </div>
                <div style="height:1rem;margin: 10rem 15rem 0rem 15rem">
                    <hr/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref} from "vue";

let banner = ref({}) //banner图
let bands_show = ref('dys') //品牌展示

const getBanner = async () => {
    await fetch("/api/banner/detail/3").then(response => response.json()
    ).then(data => {
        banner.value = data
    }).catch(error => {
        console.error('请求失败', error);
    });
}
const bandsChange = (e) => {
    bands_show.value = e
}

getBanner()
</script>
<script>
export default {
    name: 'BandsPage',
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

.content-center {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bands-jz-div {
    background-color: #FFFFFF;
    margin: 3rem;
    width: 96%;
    height: 40rem;
    border-radius: 8px;
}

.bands-jz {
    height: 15rem;
    width: 80%;
    margin: 0rem 10rem 0rem 10rem;

}

.bands-jz img {
    cursor: pointer;
}

.bands-jz-title {
    height: 8rem;
    font-size: 2rem;
    font-weight: bold;
    padding: 5rem 0 0 15rem
}

.bands-jz-content {
    height: 10rem;
    line-height: 3rem;
    text-align: center;
    font-size: 1.3rem;
    font-weight: bold;
}

.bands-jz img {
    margin: 4rem 2rem 2rem 2rem;
    width: 22.5rem;
    height: 8rem;
    float: left;
}

.bands-hs-div {
    background-color: #FFFFFF;
    margin: 3rem;
    padding-bottom: 2rem;
    width: 96%;
    height: 90rem;
    border-radius: 8px;
}
</style>
