<script setup>
import "@tailwindcss/typography";
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
const {  params } = useRoute()

const article = ref({
  "id": 1,
  "title": "BFB集团首席质量官王冠博士接受《中国质量万里行》专访",
  "intro": "BFB集团首席质量官王冠博士接受《中国质量万里行》专访",
  "cover": require("@/assets/集团资讯/集团资讯 -中国质量万里行.jpg"),
  "content": "",
  "date": "2024-01-05 10:32:11",
  "author_img": "aaa",
  "name": "数据中心",
  "tag": 1,
  "weight": 1,
  "created_at": "2024-01-05T10:33:45.000000Z",
  "updated_at": "2024-01-05T10:43:16.000000Z"
})

const getNewsData = (id) => {
  fetch("/api/news/detail/" + id).then(response => {
    return response.json()
  }).then(data=>{
    article.value = data
    // article.value.cover = require("@/assets/集团资讯/img.png")
  })
}

function formatDate(dateString) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

onMounted(() => {
  getNewsData(params.id)
})

</script>
<template>
  <img :src="article.cover" alt="" class="w-full">
  <div class="container mx-auto items-center content-center p-8 mt-10 w-screen">
    <h1 class="text-3xl font-bold mb-4">{{ article.title }}</h1>
    <p class="text-gray-600 mb-2">作者: {{ article.name }}</p>
    <p class="text-gray-600 mb-4">日期: {{ formatDate(article.date) }}</p>
    <div v-html="article.content" class="w-11/12 justify-center content-center"></div>
  </div>
</template>



<style scoped>
/* 使用 Tailwind CSS 中的 typography 插件来美化文章内容 */


/* 自定义样式可以在这里添加 */
</style>
