<template>
    <div>
        <div style="padding-top: 3.95rem;">
            <div style=" position: relative;">
                <img class="w-screen" :src="banner.cover">
            </div>
            <div class="animate__animated animate__fadeIn"
                 style="position:absolute;top:35%;text-align:center;;width:100%;z-index:20;color: #f0f0f0">
                <div class="flex justify-center space-x-3.5" style="font-size:2.5rem;">
                    <h1 class="font-bold">{{ banner.title}}</h1>
                </div>
                <div class="text-center mt-5" style="font-size:large;font-weight:400;">{{ banner.intro }}</div>
            </div>
        </div>
        <div class="about-nav-div">
            <ul>
                <li><a @click="scrollToSection('section1')">集团简介</a></li>
                <li><a @click="scrollToSection('section2')">企业文化</a></li>
                <li><a @click="scrollToSection('section3')">发展历程</a></li>
                <li><a @click="scrollToSection('section4')">专业实力</a></li>
                <li><a @click="scrollToSection('section5')">荣誉奖项&认证</a></li>
            </ul>
        </div>
        <div class="about-content-div">
            <div class="section-div">
                <section id="section1" class="section-bg-color-f">
                    <div class="section-content-div">
                        <h3 class="section1-content-title">集团简介</h3>
                        <div class="section3-content-div">
                            <div style="float: left;padding-right: 15rem">
                                <img style=" width: 50rem;height:32rem;"
                                     :src="info.cover">
                            </div>
                            <div v-html="info.content"  ></div>
                        </div>
                    </div>
                </section>
            </div>
            <div class="section-div">
                <section id="section2" class="section-bg-color-e">
                    <div class="section-content-div">
                        <span class="section2-content-title">
                            企业文化
                        </span>
                        <div class="section3-content-div-dec">
                            <div class="section2-div section2-div-margin-right">
                                <div class="section2-content-div" v-for="(item, index) in culture" :key="index" :id="index" v-show="index < 3">
                                    <p class="section2-title">{{ item.title }}</p>
                                    <p class="section2-p">{{ item.subtitle }}</p>
                                </div>
                            </div>
                            <div class="section2-div section2-div-margin-left">
                                <div class="section2-content-div" v-for="(item, index) in culture" :key="index" :id="index" v-show="index > 2">
                                    <p class="section2-title">{{ item.title }}</p>
                                    <p class="section2-p">{{ item.subtitle }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div class="section-div">
                <section id="section3" style="height: 55rem;" class="section-bg-color-f">
                    <div class="section3-content-title">
                        发展历程
                    </div>
                    <div style="padding: 2% 0 0 10%;">
                        <div v-for="(item, index) in developList" :key="index" :id="index" v-show="ls_key === index"
                             class="section3-content">
                            <p v-for="(items, indexs) in item.list" :key="indexs" style="padding-top: 2.5rem">
                                <span style="font-size: 48px;font-weight: bold;">{{ items.subtitle }}</span>
                                <span style="font-weight:bold">月</span>
                                <span class="section3-content-text">
                                    {{ items.intro }}
                                </span>
                            </p>

                        </div>
                        <div class="section3-content-slide">
                            <button @click="prevSlide" :disabled="currentIndex === -1">
                                <img style="width: 30px;height: 25px;" src="../assets/images/about/top.png"/>
                            </button>
                            <div class="slideshow">
                                <div :style="{ transform: `translateY(${-currentIndex * (10 / visibleSlides)}rem)` }"
                                     class="slides">
                                    <div v-for="(slide, index) in developList" :key="index" class="slide"
                                         :class="{ 'active': index-1 >= currentIndex && index-1 < currentIndex + visibleSlides }">
                                        {{ slide.year }}
                                    </div>
                                </div>
                            </div>
                            <button @click="nextSlide" :disabled="currentIndex + visibleSlides >= developList.length-1">
                                <img style="width: 30px;height: 30px;" src="../assets/images/about/botem.png"/>
                            </button>
                        </div>
                    </div>
                </section>
            </div>
            <div class="section-div">
                <section style="height: 110rem" id="section4" class="section-bg-color-f">
                    <div class="section4-content-title">
                        专业实力
                    </div>

                    <div  v-for="(item, index) in strength" :key="index" :id="index">
                        <div class="section4-content-div">
                            <div class="section4-content-text">
                                <p class="section4-content-t">{{ item.title }}</p>
                                <p class="section4-content-a">{{ item.subtitle }}</p>
                                <p class="section4-content-b">{{ item.intro }}</p>
                                <p class="section4-content-b" v-html="item.content">
                                </p>
                            </div>
                            <div class="section4-content-img">
                                <img :src="item.cover">
                            </div>
                        </div>
                        <hr/>
                    </div>
                </section>
            </div>
            <div class="section-div">
                <section style="height: 56rem;background-color: #f0f0f0" id="section5" class="section-bg-color-e">
                    <div class="section5-content-title">
                        荣誉奖项&认证
                    </div>
                    <div
                        class="section5-year-start">
                        <div class="gradient-text">{{ years.start }}</div>
                    </div>
                    <div
                        class="section5-year-end">
                        <div class="gradient-text">{{ years.end }}</div>
                    </div>
                    <div style="padding: 28rem 0 0 2rem;">
                        <div class="carousel">
                            <div class="carousel-inner">
                                <div class="carousel-div" v-for="(item, index) in awards" :key="index">
                                    <img :src="item.cover">
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <YearScroller/>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref} from "vue";

let ls_key = 1
const currentIndex = ref(0)
const visibleSlides = ref(1) // 设置显示的幻灯片数量
let developList = ref([]) //发展历程
let banner = ref({}) //banner图
let info = ref({}) //集团简介
let culture = ref([]) //企业文化
let strength = ref([]) //专业实力
let awards = ref([]) //荣誉奖项&认证
let years = {
    start: '',
    end: ''
}
const prevSlide = () => {
    if (currentIndex.value > -1) {
        currentIndex.value--;
        ls_key--;
    }
    console.log(ls_key)
}
const nextSlide = () => {
    if (currentIndex.value + visibleSlides.value < developList.value.length) {
        currentIndex.value++;
        ls_key++;
    }
    console.log(ls_key)
}
const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
        section.scrollIntoView({behavior: 'smooth'});
    }
}

const getAboutAll = async () => {
    await fetch("/api/about/all/").then(response => response.json()
    ).then(data => {
        optionfzls(data)
    }).catch(error => {
        console.error('请求失败', error);
    });
}
const getBanner = async () => {
    await fetch("/api/banner/detail/2").then(response => response.json()
    ).then(data => {
        banner.value = data
    }).catch(error => {
        console.error('请求失败', error);
    });
}

const optionfzls = (data) => {
    let yaerObj = {}
    culture = ref([])
    strength = ref([])
    awards = ref([])
    data.forEach((item) => {
        if (item.cate==3){
            if (!yaerObj[item.title]) {
                yaerObj[item.title] = []
            }
            yaerObj[item.title].push(item)
        }
        if (item.cate==1){
            info.value = item
        }
        if (item.cate==2){
            culture.value.push(item)
        }
        if (item.cate==4){
            strength.value.push(item)
        }
        if (item.cate==5){
            awards.value.push(item)
        }
    })
    years.start = awards.value[0].title
    years.end = awards.value[awards.value.length - 1].title
    let developLists = []
    for (let key in yaerObj) {
        developLists.push({
            year: parseInt(key),
            list: [...yaerObj[key]].sort((a, b) => b.weight - a.weight)
        })
    }
   developList.value = [...developLists].sort((a, b) => b.year - a.year)
}

getBanner()
onMounted(() => {
    getAboutAll()
})

</script>

<style scoped>
body {
    margin: 0;
    padding: 0;
}

.slideshow {
    width: 100%;
    height: 30rem;
    overflow: hidden;
}

.slides {
    display: flex;
    flex-direction: column;
    transition: transform 0.5s ease-in-out;
    height: 10rem;
}

.slide {
    flex: 0 0 calc(100% / 3);
    box-sizing: border-box;
    font-size: 10rem;
    font-weight: 800;
    opacity: 0.4;
    /*height: 400px;*/
    color: #FFC80F;
    line-height: 1;
    transition: opacity 0.3s ease-in-out;
}

.slide.active {
    opacity: 1.2;
}

button {
    margin-left: 15%;
    cursor: pointer;
    padding: 5px 10px;
    font-size: 14px;
}

p span {
    display: inline-block;
    margin-right: 10px; /* 可以根据需要设置间距 */
}

.about-nav-div {
    width: 100%;
    height: 4rem;
}

.about-nav-div ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-nav-div ul li {
    font-size: 1.5rem;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

li {
    height: 80px;
    padding: 25px 5% 20px 6%;
    font-size: 20px;
    font-weight: bolder;
    cursor: pointer;
}

li:hover {
    background-color: #FFC80F;
}

.about-content-div {
    background-color: #F0F0F0;
}

.section-div {
    padding: 2% 2% 2% 2%;
}

section {
    height: 50rem;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    border-radius: 8px;
}

.section1-content-title {
    font-size: 2.5rem;
    font-weight: bold;
    padding: 5rem 0 0 5rem
}

.section-bg-color-f {
    background-color: #FFFFFF;
}

.section-bg-color-e {
    background-color: #f0f0f0;
    height: 45rem;
}

.section-content-div {
    padding: 2.5rem 5rem 2.5rem 5rem;
}

.section3-content-div-dec {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5rem;
}

.section1-content-text {
    float: left;
    padding: 5rem 0 0 10rem;
    font-size: 1.4rem;
    line-height: 3.5rem;
    padding-left: 15rem;
}

.section2-content-title {
    font-size: 2.5rem;
    font-weight: bold;
    padding: 0rem 0 0 5rem
}

.section2-title {
    font-size: 1.8rem;
    line-height: 1.8;
    font-family: 'Arial', sans-serif;
}

.section2-p {
    font-size: 1.2rem;
    color: #939393;
}

.section2-content-div {
    padding-top: 8%;
}

.section2-div {
    width: 45%;
    height: 32rem;
    text-align: center;
    float: left;
    background-color: #FFFFFF;
}

.section2-div-margin-left {
    margin-left: 2rem;
}

.section2-div-margin-right {
    margin-right: 2rem;
}

.section3-content {
    float: left;
    width: 45%;
    height: 400px;
}

.section3-content-div {
    padding: 3% 0 0 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section3-content-title {
    font-size: 2.5rem;
    padding: 5rem 0 0 10rem;
    font-weight: bolder;
}

.section3-content-text {
    width: 70%;
    height: 20%;
    padding-left: 8%;
    font-size: 1.5rem;
    line-height: 3rem;
    font-weight: bold;
}

.section3-content-slide {
    float: left;
    width: 45%;
    height: 500px;
    margin-left: 10%;
}

.section4-content-title {
    font-size: 2.5rem;
    padding: 5rem 0 0 10rem;
    font-weight: bolder;
}

.section4-content-div {
    height: 25rem;
    padding: 0% 5rem 0 5rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section4-content-t {
    font-weight: bolder;
    font-size: 2.3rem;
    padding-bottom: 5px
}

.section4-content-text {
    width: 45%;
    float: left;
    margin-right: 5%;
}

.section4-content-a {
    font-size: 1.5rem;
    line-height: 2rem;
    color: #848484
}

.section4-content-b {
    padding-top: 4%;
    font-size: 16px;
    color: #848484;
}

.section4-content-img {
    width: 35%;
    float: left;
}

.section5-content-title {
    font-size: 2.5rem;
    padding: 5rem 0 0 10rem;
    font-weight: bolder;
}

.section5-year-start {
    padding-left: 10rem;
    height: 16rem;
    position: absolute;
    z-index: 0;
    background-color: rgba(255, 255, 255, 0);
    z-index: 1;
}

.section5-year-end {
    padding-left: 32rem;
    margin-top: 10rem;
    position: absolute;
    height: 16rem;
    background-color: rgba(255, 255, 255, 0);
    z-index: 2;
}

.carousel {
    overflow: hidden;
    width: 100%; /* 走马灯容器宽度 */
    margin: 0 auto;
    position: relative;
    transform: translate3d(0, 0, 0);
    height: 25rem;
}

.carousel-inner {
    display: flex;
    animation: carouselAnimation 15s linear 0s infinite; /* 走马灯动画，10秒完成一次循环 */
    margin: 3rem;
    height: 18rem;
    width: 32rem;
}

.carousel-div {
    flex: 0 0 auto; /* 每个走马灯项占满容器宽度 */
    width: 32rem;
    height: 16rem;
}

.carousel-div img {
    width: 100%; /* 图片宽度占满容器 */
    height: auto; /* 保持纵横比 */
    transition: transform 3s ease-in-out;

}

.carousel-div:hover {
    background-color: #FFC80F;
    transform: scale(1.5);
}

.carousel-inner:hover {
    animation-play-state: paused; /* 鼠标停留时暂停动画 */

}

@keyframes carouselAnimation {
    to {
        transform: translateX(-128rem);
    }
}

.gradient-text {
    width: 100%;
    height: 100%;
    font-size: 16rem;
    font-weight: bold;
    background: linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 50%, #FFFFFF 55%, #f0f0f0 100%);
    -webkit-background-clip: text;
    color: transparent; /* 设置文字颜色为透明，以显示背景渐变效果 */
    display: inline-block; /* 避免块级元素换行影响渐变效果 */
}


</style>
