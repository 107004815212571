<template>
  <div class="flex justify-center items-center mx-auto">
    <div class="flex items-center justify-between space-x-10">
      <div
          v-for="(item, index) in items"
          :key="index"
          class="carousel-item"
          @mouseenter="stopCarousel" @mouseleave="startCarousel"
           >
        <img :src="item.image" class="w-full h-full object-cover" alt="cover"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
    }
  },
  data() {
    return {
      isHovered: false,
      currentIndex: 0, // 当前轮播项的索引
      translateValue: 0, // 用于控制轮播项的水平偏移量
    };
  },
  methods: {
    stopCarousel() {
      this.isHovered = true;
      console.log(this.isHovered)
    },
    startCarousel() {
      this.isHovered = false;
      console.log(this.isHovered)
    },

  }
};
</script>

<style scoped>

.running-carousel {
  animation-play-state: paused;
  height: 300px;
  width: 100%;
  max-width: 80%;
  animation-direction: alternate-reverse;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-name: slideRightToLeft;
  animation-timing-function: ease-in-out;

}

.stopped-carousel {
  animation-play-state: paused;
  height: 300px;
  width: 100%;
  animation-direction: alternate-reverse;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-name: slideRightToLeft;
  animation-timing-function: ease-in-out;
}
.carousel-item{
  //height: 300px;
  //width: 60%;
  background-color: #F1F2F4;
}

.carousel-item :hover{
  transform: scale(1.1);
  transition: transform 3s ease;
  background-color: #FDCA48;
}

@keyframes slideRightToLeft {
  0% {
    transform: translateX(30%); /* 初始位置在右侧 */
  }
  100% {
    transform: translateX(35%); /* 最终位置在左侧 */
  }
}
</style>
