<!-- App.vue -->
<template>
  <div class="flex flex-col min-h-screen">
    <NavBar class="z-50"/>
    <router-view></router-view>
    <HomeFooter class="mb-0 bottom-0"/>
  </div>

</template>

<script>
import NavBar from "@/components/NavBar.vue";
import HomeFooter from "@/components/HomeFooter.vue";

export default {
  name: 'App',
  components: {HomeFooter, NavBar},
};
</script>

<style>
body{
  background-color: #F2F2F1;
}
</style>
