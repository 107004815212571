import { createApp } from 'vue'
import App from './App.vue'
import HomePage from './components/HomePage.vue';
import AboutUs from './components/AboutUs.vue';
import BrandsPage from './components/Brands.vue';
import GroupNews from './components/GroupNews.vue';
import JoinUs from './components/JoinUs.vue';
import {createRouter, createWebHistory} from "vue-router";

import "@/assets/css/ali.css";
import 'tailwindcss/tailwind.css';
import NewsDetail from "@/components/NewsDetail.vue";

const routes = [
    { path: '/', component: HomePage },
    { path: '/about', component: AboutUs },
    { path: '/brands', component: BrandsPage },
    { path: '/news', component: GroupNews },
    { path: '/join', component: JoinUs },
    { path: '/news-detail/:id', component: NewsDetail },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

createApp(App).use(router).mount('#app');
