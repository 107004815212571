<template>
  <div class="flex flex-col h-full items-center mb-32">
    <img :src="banner.cover" style="padding-top:  3.95rem" class="w-screen" alt="">
      <div class="animate__animated animate__fadeIn"
           style="position:absolute;top:35%;text-align:center;;width:100%;z-index:20;color: #f0f0f0">
          <div class="flex justify-center space-x-3.5" style="font-size:2.5rem;">
              <h1 class="font-bold">{{ banner.title }}</h1>
          </div>
          <div class="text-center mt-5" style="font-size:large;font-weight:400;">{{ banner.intro}}</div>
      </div>
    <div class="px-10 mt-20">
      <div class="mt-1 bg-white rounded-2xl px-32 py-20">
        <h3 class="text-4xl py-10">最新动态</h3>
        <div class="flex mt-8 justify-center space-x-6">
          <div class="component-card-news-list">
            <div class="component-news-list">
              <a class="flex common-border-radius transition-hover component-news-list-item bg-gray-400"
                 v-for="newsItem in newsList" :key="newsItem.id"
                 target="_blank" :href="/news-detail/ + newsItem.id">
                <div class="common-border-radius component-news-img">
                  <img
                      style="height: 100%; width: 100%; object-fit: cover;"
                      :src="newsItem.cover"
                      :alt="newsItem.title"></div>
                <div class="component-news-info">
                  <div class="common-font-size-21 component-title">{{ newsItem.title }}</div>
                  <hr class="border-solid border-gray-700"/>
                  <div class="date-author">
                    <div class="common-font-size-12 date-date">{{ newsItem.date }}</div>
                    <div class="author-arrow">
                      <div class="component-author" :style="{backgroundImage: 'url(' + newsItem.author_img + ')'}"
                           style="background-repeat: no-repeat; background-size: contain; background-position: center center;"></div>
                      <i class="next-icon next-icon-arrow-right next-medium"></i></div>
                  </div>
                </div>
                <div class="mobile-new-item">
                  <div class="mobile-new-item-top"><img
                      :src="newsItem.cover"
                      :alt="newsItem.title" class="mobile-new-item-img">
                    <div class="common-font-size-21 mobile-new-item-title">{{ newsItem.title }}</div>
                  </div>
                  <div class="mobile-new-item-bottom">
                    <div class="common-font-size-12 mobile-new-item-date">{{ newsItem.date }}</div>
                    <div class="mobile-new-item-author-arrow">
                      <div class="mobile-new-item-author" :style="{backgroundImage: 'url(' + newsItem.author_img + ')'}"
                           style="background-repeat: no-repeat; background-size: contain; background-position: center center;"></div>
                      <i class="next-icon next-icon-arrow-right next-medium"></i></div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="flex justify-center items-center mt-10 mb-10">
          <!--        <a class="flex justify-center text-center mx-auto w-28 items-center border border-gray-500 text-1xl" href="/news">上一页</a>-->
          <a class="flex justify-center text-center mx-auto w-28 items-center border border-gray-500 text-1xl"
             href="/news">下一页</a>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import "@/assets/css/news.css";

const newsList = ref([])
const banner = ref({})

const getNewsList = async () => {
  let response = await fetch('/api/news/list')
  newsList.value = await response.json()
  console.log(newsList.value)
  return newsList
}
const getBanner = async () => {
    await fetch("/api/banner/detail/4").then(response => response.json()
    ).then(data => {
        banner.value = data
    }).catch(error => {
        console.error('请求失败', error);
    });
}
onMounted(() => {
  newsList.value = []
  getNewsList()
    getBanner()
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
