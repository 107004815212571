<template>
  <footer class="mt-auto w-screen mx-auto bg-white py-4">
    <div class="w-11/12 mx-auto">
      <div class="flex  mx-auto items-center">
        <div class="content-center ml-24">
          <img src="@/assets/logo.png" style="height: 2.875rem;" alt="Logo"/>
        </div>
        <div style="margin-left: 30%;">
          <a href="https://beian.miit.gov.cn/" target="_blank" class="text-nowrap">皖ICP备2024033684号</a>
        </div>
        <div class="flex justify-center mx-auto space-x-20 items-center" style="margin-left: 20%">
          <span>联系我们</span>
          <div v-for="(link,key) in links" :key="key" class="">
            <img v-if="link.show" :src="link.qrcode" class="qrcode-container"  alt="Image"/>
            <a href="/" target="_blank" class="flex justify-center items-center content-center"
               @mouseover="showImage(key)"
               @mouseleave="hideImage(key)">
              <img :src="link.icon" style="height: 1.875rem;width: 1.875rem;" alt="Logo"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import {onMounted, ref} from "vue";

const links = ref([])

onMounted(() => {
  links.value = [
    {icon: require("@/assets/wexin.png"), qrcode: require("@/assets/微信.jpg"), show: false},
    {icon: require("@/assets/weibo.png"), qrcode: require("@/assets/微博.jpg"), show: false},
    {icon: require("@/assets/baidu.png"), qrcode: require("@/assets/百度.jpg"), show: false},
  ]
})

const showImage = function (index) {
  console.log(links.value[index].qrcode.width)
  // 当鼠标悬浮时显示图像
  links.value[index].show = true;
}
const hideImage = function (index) {
  console.log(links.value[index].qrcode.width)
  // 当鼠标离开时隐藏图像
  links.value[index].show = false;
}

</script>

<style scoped>
/* 初始状态下隐藏二维码容器 */
.qrcode-container {
  z-index: 1000;
  position: fixed;
  bottom: 3rem;
  margin-left: -5rem;
  height: 12rem;
}

.qrcode-container + ::before{
  content: "";
}

  /* 图标样式 */
.icon {
  text-align: center;
  align-items: center;
  line-height: 50px;
  cursor: pointer;
}

/* 鼠标悬停时显示二维码容器 */
.icon:hover{
  position: fixed;
}
</style>
